<template>
  <docs-layout>
    <div class="mb-10 flex flex-col items-start space-y-4">
      <h2 class="font-bold text-2xl">Default (with all variants)</h2>
      <div class="space-x-4 flex items-end">
        <asom-button
          v-for="(v, index) in variants" :key="index"
          :variant="v"
          :text="getButtonText(v)"
        />
      </div>
    </div>
    <div class="mb-10 flex flex-col items-start space-y-4">
      <h2 class="font-bold text-2xl">Outline (with all variants)</h2>
      <div class="space-x-4 flex items-end">
        <asom-button
          v-for="(v, index) in variants.slice(0, -1)" :key="index"
          outline
          :variant="v"
          :text="getButtonText(v)"
        />
      </div>
    </div>
    <div class="mb-10 flex flex-col items-start space-y-4">
      <h2 class="font-bold text-2xl">Sizes</h2>
      <div class="space-x-4">
        <asom-button
          v-for="(s, index) in sizes" :key="index"
          :size="s"
          :text="getButtonText_size(s)"
        />
      </div>
    </div>
    <div class="mb-10 flex flex-col items-start space-y-4 ">
      <h2 class="font-bold text-2xl">Disabled</h2>
      <p>In disabled state, click event will not be triggered</p>
      <asom-button disabled text="Disabled Button"></asom-button>
    </div>
    <div class="mb-10 flex flex-col items-start space-y-4 ">
      <h2 class="font-bold text-2xl">With icon</h2>
      <div class="space-x-4 flex">
        <asom-button text="Button" icon="log-in" icon-position="left"></asom-button>
        <asom-button text="Button" icon="log-in" icon-position="right"></asom-button>
        <asom-button icon="log-in"></asom-button>
      </div>
    </div>
    <div class="mb-10 flex flex-col items-start space-y-4 ">
      <h2 class="font-bold text-2xl">Loading</h2>
      <p>In loading state, button still receives click event. Developers must check and disable button's default behavior accordingly in their logics.</p>
      <div class="space-x-4 flex">
        <asom-button text="Loading Button" loading></asom-button>
        <asom-button text="Loading Button" loading loading-text="Loading Text"></asom-button>
      </div>
    </div>
    <div class="lg:w-1/4 md:w-full flex flex-col gap-2 mb-12">
      <asom-button text="button 1" />
      <asom-button text="button 2 with long text" />
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      variants: ['primary', 'secondary', 'success', 'error', 'warning', 'link'],
      sizes: ['xs', 'sm', 'md', 'lg', 'xl']
    }
  },
  methods: {
    getButtonText(variant) {
      return variant.charAt(0).toUpperCase() + variant.slice(1) + ' Button'
    },
    getButtonText_size(variant) {
      return 'Button ' + variant.charAt(0).toUpperCase() + variant.slice(1)
    }
  }
}
</script>